<template>
    <div>
        <div v-if="isDivision">
            <DivisionDashboard></DivisionDashboard>
        </div>
        <div v-if="isAssociation">
            <AssociationDashboard></AssociationDashboard>
        </div>
        <v-content v-else>
            <!--<v-breadcrumbs :items="snackbar.breadcrumbsItems" large></v-breadcrumbs>-->

            <template>
                <v-sheet color="white" align="center" tile>
                    <v-row class="fill-height" align="center" justify="center">
                        <v-col cols="12" sm="12">

                            <img class="white--text align-end"
                                 :src="branchInformation.logo"
                                 style="width: 100%; height: 550px; object-fit: fill;">




                        </v-col>
                    </v-row>
                </v-sheet>
            </template>
            <v-sheet color="white" align="center" tile>
                <v-row class="fill-height" align="center" justify="center">
                    <v-col cols="12" sm="12">
                        <v-title class="display-1" style="font-weight: bold; color:white; background-color:darkblue;padding:5px; border-radius:10px;" v-if="!isNepaliLanguage">{{branchInformation.branchName}}</v-title>
                        <v-title class="display-1" style="font-weight: bold; color:white; background-color:darkblue;padding:5px; border-radius:10px;" v-else>{{branchInformation.branchNameNepali}}</v-title>
                        <br />
                        <p style="font-weight: bold;margin-top:5px" v-if="!isNepaliLanguage">
                            {{branchInformation.branchAddress}}
                        </p>
                        <p style="font-weight: bold;margin-top:5px" v-else>
                            {{branchInformation.branchAddressNepali}}
                        </p>
                    </v-col>
                </v-row>
            </v-sheet>

            <template>

                <v-row>
                    <v-col cols="12" sm="3">
                        <v-card color="#006400" elevation="5" style="height:180px">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <p id="passengers" >{{AviationDailyData.totalPassanger}}</p>
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <div>
                                                <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                    {{$t('total_no_of_passenger_day')}}
                                                </v-list-item-subtitle>
                                                <v-icon color="white" x-large>flight</v-icon>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-list-item three-line>
                                        <v-list-item-content class="text-right">
                                            <div>
                                                <!-- <v-list-item-subtitle class="subtitle-1 white--text" style="font-size:19px"
                                              >742,824</v-list-item-subtitle
                                            >
                                            <v-list-item-subtitle
                                              class="white--text"
                                              style="font-size: 11px"
                                              >CHANGE TO</v-list-item-subtitle
                                            > -->
                                                <v-list-item-subtitle id="title" class="white--text"
                                                                      style="font-size: 26px">
                                                    {{AviationDailyData.totalPassanger}}
                                                </v-list-item-subtitle>

                                                <!--<v-list-item-subtitle class="white--text mb-5"
                                                                  style="font-size: 14px">Actual</v-list-item-subtitle>
                                            <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                            <span class="white--text">3211</span><br />
                                            <span class="white--text">(2.16%)</span>-->
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-card color="#FFA500" elevation="5" style="height:180px">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <p id="passengers" style="float:right; margin-right:10px; font-size:26px; color:white">
                                        {{AviationMonthlyData.totalPassanger}}</p>
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <div>
                                                <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                    {{$t('total_no_of_passenger_month')}}
                                                </v-list-item-subtitle>
                                                <v-icon color="white" x-large>flight_land</v-icon>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-list-item three-line>
                                        <v-list-item-content class="text-right">
                                            <div>
                                                <v-list-item-subtitle id="title" class="white--text"
                                                                      style="font-size: 26px">{{AviationMonthlyData.totalPassanger
                                        }}</v-list-item-subtitle>

</div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-card color="#077dfa" elevation="5" style="height:180px">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <p id="passengers" style="float:right; margin-right:10px; font-size:26px; color:white">
                                        {{AviationDailyData.totalFight}}
                                    </p>
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <div>
                                                <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                    {{$t('total_no_of_flights_day')}}
                                                </v-list-item-subtitle>
                                                <v-icon color="white" x-large>flight_takeoff</v-icon>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-list-item three-line>
                                        <v-list-item-content class="text-right">
                                            <div>

                                                <v-list-item-subtitle id="title" class="white--text"
                                                                      style="font-size: 26px">{{AviationDailyData.totalFight}}</v-list-item-subtitle>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-card color="#8B0000" elevation="5" style="height:180px">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <p id="passengers" style="float:right; margin-right:10px; font-size:26px; color:white">
                                        {{AviationMonthlyData.totalFight}}
                                    </p>
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <div>
                                                <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                    {{$t('count_of_flight_type_month')}}
                                                </v-list-item-subtitle>
                                                <v-icon color="white" x-large>flight_class</v-icon>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-list-item three-line>
                                        <v-list-item-content class="text-right">
                                            <div>
                                                <v-list-item-subtitle id="title" class="white--text"
                                                                      style="font-size: 26px"> {{AviationMonthlyData.totalFight}}</v-list-item-subtitle>

                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>


                    <v-col cols="3"
                           v-for="title in dashboardItems"
                           v-bind:key="title.link"
                           class="viewCard">
                        <router-link :to="title.link">
                            <HoverCard :cardData="title"></HoverCard>
                            <!--<v-card :elevation="10"
            class="blue darken-1 text-center"
            height="100"
            width="100%">
        <v-list-item three-line link>
            <v-list-item-content>
                <p id="subboxtitle">
                    {{title.name}}
                </p>
                <v-list-item-subtitle class="white--text header-6 mt-1">
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>-->
                        </router-link>
                    </v-col>
                    <!--<v-col cols="6"
                       v-for="title in titles"
                       v-bind:key="title.link"
                       class="viewCard">
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <router-link :to="title.link">
                                <v-card :elevation="10"
                                        class="blue darken-1 text-center"
                                        height="100"
                                        width="100%">
                                    <div>
                                        <v-list-item three-line>

                                            <v-list-item-content>
                                                <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                                <v-list-item-title class="white--text subtitle-1 mt-1 pa-4">
                                                    {{title.name}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="white--text header-6 mt-1">
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </div>
                                </v-card>
                            </router-link>
                        </template>
                    </v-tooltip>
                </v-col>-->
                </v-row>
            </template>


            <template>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-card class="pa-5" elevation="5">
                            <h1 class="headline mb-2 primary--text"
                                style="border-bottom: 1px solid grey">
                                {{$t('performance_of_airlines')}}
                            </h1>
                            <template>
                                <div id="app">
                                    <GChart type="ColumnChart"
                                            :data="chartData"
                                            :options="chartOptions"
                                            style="height: 350px" />
                                </div>
                            </template>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-card class="pa-5" elevation="5">
                            <h1 class="headline mb-2 primary--text"
                                style="border-bottom: 1px solid grey">
                                {{$t('monthly_performance_of_airlines')}}
                            </h1>
                            <template>
                                <div id="app">
                                    <GChart type="ColumnChart"
                                            :data="monthlyData"
                                            :options="chartOptions"
                                            style="height: 350px" />
                                </div>
                            </template>
                        </v-card>

                    </v-col>
                </v-row>
            </template>
            <template>
                <v-row>
                    <v-col cols="12" sm="6">
                        <div slot="table-actions">
                            <!--<div class="my-1">
                <v-btn color="primary" @click.stop="addModal">Passenger Form</v-btn>
            </div>-->
                        </div>
                        <v-expansion-panels hover>
                            <v-expansion-panel>
                                <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-container fluid>
                                        <v-row align="start" class="mt-4">
                                            <v-col class="float-right" cols="12" sm="4">
                                                <v-text-field v-model="serverParams.fullName"
                                                              :label="$t('full_name') "
                                                              clearable
                                                              dense
                                                                      outlined
                                                              required></v-text-field>
                                            </v-col>
                                            <v-col class="float-right" cols="12" sm="4">
                                                <v-select v-model="serverParams.flightNumber" :items="airList"
                                                          item-text="value"
                                                          item-value="id"
                                                          dense
                                                                      outlined
                                                          clearable
                                                          :label="$t('flight_number')"></v-select>
                                            </v-col>
                                            <v-col class="float-right" cols="12" sm="4">
                                                <v-autocomplete v-model="serverParams.destionation"
                                                                :items="Destinations"
                                                                item-text="value"
                                                                item-value="value"
                                                                dense
                                                                      outlined
                                                                clearable
                                                                :label="$t('table.destinations')"></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row align="center" justify="center">
                                            <v-btn @click="searchInput" small color="primary" dark>
                                                <i class="material-icons">search</i>{{$t('search')}}
                                            </v-btn>
                                            <v-btn color="rgb(157,25,25)" class="ml-5" small dark @click="clearInput">
                                                <i class="material-icons">clear</i>{{$t('clear')}}
                                            </v-btn>
                                        </v-row>
                                    </v-container>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <vue-good-table :columns="columns"
                                        :rows="rows"
                                        mode="remote"
                                        :totalRows="customerData.totalRecords"
                                        :pagination-options="{ enabled: true,mode: 'records' ,perPageDropdown: [50, 100,200]}"
                                        @on-page-change="onPageChange"
                                        @on-sort-change="onSortChange"
                                        @on-per-page-change="onPerPageChange"
                                        styleClass="vgt-table condensed">
                            <template slot="table-column" slot-scope="props">
                                <span>
                                    {{ $t(props.column.label) }}
                                </span>
                            </template>
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'flightDateTime'">
                                    <span>{{props.row.flightDateTime | moment("DD-MMM-YYYY, h:mm:ss a")}}</span>
                                </span>
                                <span v-else-if="props.column.field == 'actions'">
                                    <v-icon small class="mr-2" @click.stop="editItem(props.row, 'CustomerForm')">edit</v-icon>
                                    <v-icon small @click="deleteItemRoom(props.row)">delete</v-icon>
                                </span>
                            </template>
                        </vue-good-table>
                        <v-dialog v-model="snackbar.formModal"
                                  fullscreen
                                  hide-overlay
                                  transition="dialog-bottom-transition"
                                  persistent>
                            <v-card>
                                <!-- <component :is="snackbar.dynamicComponent.current"
                           @dialogueClose="dialogueClose"
                           @formResponse="formResponse"
                           v-bind="customerData.updateItem"
                ></component>-->
                                <CustomerForm @dialogueClose="dialogueClose"
                                              @formResponse="formResponse"
                                              v-if="snackbar.createFormComponent"></CustomerForm>
                                <CustomerEditForm @dialogueClose="dialogueClose"
                                                  @formResponse="formResponse"
                                                  v-else-if="snackbar.editFormComponent"
                                                  :editItemIndex="customerData.editItemIndex"></CustomerEditForm>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="snackbar.dialogDelete" max-width="500px">
                            <deleteListData :deleteFormData="customerData.deleteFormData"
                                            @formResponse="onResponseDelete"></deleteListData>
                        </v-dialog>
                        <v-snackbar v-model="snackbar.snackbar"
                                    :color="snackbar.color"
                                    :timeout="snackbar.timeout"
                                    top>
                            {{ snackbar.text }}
                            <v-btn dark
                                   text
                                   @click="snackbar.snackbar = false">
                                {{$t('close')}}
                            </v-btn>
                        </v-snackbar>
                    </v-col>





                    <v-col cols="12" sm="6">
                        <v-card class="pa-5" elevation="5">
                            <h1 class="headline mb-2 primary--text"
                                style="border-bottom: 1px solid grey">
                                {{$t('destination_flights')}}
                            </h1>
                            <template>
                                <GChart type="PieChart"
                                        :options="optionss"
                                        :data="data"
                                        height="500"
                                        align="center" />

                            </template>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-card class="pa-5" elevation="5">
                            <h1 class="headline mb-2 primary--text"
                                style="border-bottom: 1px solid grey">
                                {{$t('country_wise_tourist_visit_per_month')}}
                            </h1>
                            <template>
                                <div id="app">
                                    <GChart type="ColumnChart"
                                            :data="monthlyCustomerVisitCountrywiseData"
                                            :options="chartOptions"
                                            style="height: 350px" />
                                </div>
                            </template>
                        </v-card>

                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-card>
                            <v-list style="background-color: #1976d2">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-title id="table" class="white--text"><strong>{{ $t("s_no") }}</strong></v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title id="table"
                                                           align="center"
                                                           class="white--text">
                                            <strong>{{ $t("employee_name") }}</strong>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="mr-18 white--text"><strong>{{ $t("address") }}</strong></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="white--text">
                                            <strong>
                                                {{$t("type")}}
                                            </strong>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="right">
                                        <v-list-item-title id="table" class="white--text">
                                            <strong>
                                                {{$t("contact_no")}}
                                            </strong>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        <v-card style="overflow-y: scroll; height: 350px">
                            <v-card style="height: 350px;">
                                <v-list v-for="(airEmployee, i) in airEmployeeDetails"
                                        :key="i">
                                    <v-list-item :style="{ background: airEmployee.backgroundColor }">
                                        <v-list-item-avatar>
                                            <v-title id="table" class="black--text">
                                                {{i + 1}}
                                            </v-title>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title id="table"
                                                               align="center"
                                                               class="black--text">{{ airEmployee.empName }}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table"
                                                               class="mr-18 black--text">{{ airEmployee.empAddress }}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table" class="black--text">
                                                {{ airEmployee.empType }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table" class="black--text">
                                                {{ airEmployee.empPhone }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                </v-list>
                            </v-card>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="12">
                        <v-card class="pa-5" elevation="5">
                            <h1 class="headline mb-2 primary--text"
                                style="border-bottom: 1px solid grey">
                                {{$t('country_wise_tourist_visit_per_year')}}
                            </h1>
                            <template>
                                <div id="app">
                                    <GChart type="ColumnChart"
                                            :data="yearlyCustomerVisitCountrywiseData"
                                            :options="chartOptions"
                                            style="height: 350px" />
                                </div>
                            </template>
                        </v-card>

                    </v-col>
                </v-row>

            </template>

        </v-content>
    </div>
</template>


<script>
    import axios from "axios";
    import { mapGetters } from "vuex";
    import DivisionDashboard from '../dashboard/DivisionDashboard'
    import { mdiPencil, mdiDelete } from "@mdi/js";
    import { parseJwt } from "@/mixins/parseJwt";
    import CustomerForm from "../aviation/customer/CustomerList";
    import CustomerEditForm from "../aviation/customer/CustomerEditForm";
    import AssociationDashboard from "../airport/Dashboard/AssociationDashboard";
    import deleteListData from "@/components/deleteModal";
    import publicAxios from '../../simple-axios';
    import HoverCard from '@/components/Basic/HoverCard'

    export default {
        name: "AviationSettings",
        components: {
            DivisionDashboard,
            'CustomerForm': CustomerForm,
            'CustomerEditForm': CustomerEditForm,
            'deleteListData': deleteListData,
            AssociationDashboard,
            HoverCard
        },
        mixins: [parseJwt],
        computed: {
            ...mapGetters(['getSystemUserData']),
            isDivision() {
                return this.getSystemUserData.role === 'Division'
            },
            isAssociation() {
                return this.getSystemUserData.role === "Association";
            },
            isNepaliLanguage() {
                return this.$i18n.locale === "ne";
            },
            dashboardItems() {
                return [
                    {
                        name: this.$t('passenger_type'),
                        link: "/AviationCustomerList",
                        icon: "mdi-van-passenger"
                    },
                    {
                        name: this.$t('aviation_profile'),
                        link: "/branch",
                        icon: "mdi-robot"
                    },
                    {
                        name: this.$t('survey'),
                        link: "/BranchSurveyProjects",
                        icon: "mdi-clipboard-text"
                    }
                ]
            }
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {

                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        data() {
            return {

                Destinations: [],
                airList: [],
                airEmployeeDetails:[],
                data: [],
                optionss: {
                    height: 450,
                    is3d: true
                },
                columns: [
                    {
                        label: "s_no",
                        field: "countIndex"
                    },
                    {
                        label: 'full_name',
                        field: 'fullName'
                    },
                    {
                        label: 'nationality',
                        field: 'nationality',
                    },
                    {
                        label: 'table.destinations',
                        field: 'destination',
                    },
                    {
                        label: 'flight_date',
                        field: 'flightDate',
                    },
                    {
                        label: 'gender',
                        field: 'gender',
                    },
                    { label: 'actions', field: "actions" }
                ],
                rows: [],
                customerData: {
                    totalRows: 0,
                    editItemIndex: null,
                    deleteFormData: {}
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "personInformationID",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },


                snackbar: {
                    formModal: false,
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    drawer: null,
                    drawerRight: null,
                    right: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    dialogDelete: false,
                    dynamicComponent: {
                        current: 'AirDestinationForm'
                    },
                    breadcrumbsItems: [
                        {
                            text: "Dashboard",
                            disabled: true,
                            to: "/dashboard",
                            exact: true
                        }],
                },
                
                branchInformation: [],
                callbackResponse: {
                    timeout: 6000
                },
                success: false,

                dialogEdit: false,
                dialogDelete: false,
                items: ["Foo", "Bar", "Fizz", "Buzz"],
                province: [],
                AviationDailyData: [],
                AviationMonthlyData: [],
                deleteFormData: {},
                popout: true,
                titles: [
                    //{
                    //    name: "Generate Report",
                    //    link: "/report",
                    //},
                    {
                        name: "Profile",
                        link: "/branch",
                    },
                ],
                svgpencil: mdiPencil,
                svgdelete: mdiDelete,
                dialogCreate: false,
                slides: ["", "", "", ""],
                chartData: [],
                 //["Destinations", "Incoming", "Outgoing"],
                    //["Ktm to Mt", 15000, 12000],
                    //["Ktm to Brng", 14230, 12000],
                    //["Ktm to Bharatpur", 13660, 15000],
                    //["Biratnagar to Ktm", 7030, 9020],
                    //["Bharatpur to Ktm", 12025, 11020],
                    //["Ktm to Bhadrapur", 3040, 3504],
                    //["Bhadrapur to Ktm", 2100, 1420],
                    //["Ktm to Bhairahawa", 10000, 12300],

                //["Month", "Passenger"],
                //    ["Jan", 2000],
                //    ["Feb", 2170],
                //    ["Mar", 2060],
                //    ["Apr", 3030],
                //    ["May", 40250],
                //    ["Jun", 40400],
                //    ["Jul", 50000],
                //    ["Aug", 58000],
                //    ["Sep", 68000],
                //    ["Oct", 7000],
                //    ["Nov", 58000],
                //    ["Dec", 68000],
                monthlyData: [],
                monthlyCustomerVisitCountrywiseData: [],
                yearlyCustomerVisitCountrywiseData: [],
                series: [
                    56090, 44961, 30200, 44336, 20350, 56210, 44219, 30212, 44320, 20120, 1200, 2130, 1239, 1236, 2810
                ],
                chartOptions: {
                    chart: {
                        title: "Company Performance",
                        subtitle: "Sales, Expenses, and Profit: 2014-2020",
                    },
                },
                chartsOptions: {
                    chart: {
                        height: 450,
                        is3d: true
                    },
                    labels: [
                        "Pokhara",
                        "Kathmandu",
                        "Rajbiraj",
                        "Bhairahawa",
                        "Biratnagar",
                        "Bharatpur",
                        "Bhadrapur",
                        "Dhangadhi",
                        "Tumlingtar",
                        "Nepalgunj",
                        "Surkhet",
                        "Janakpur",
                        "Simara",
                        "Varanasi"
                    ],
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 500,
                                },
                                legend: {
                                    position: "bottom",
                                },
                            },
                        },
                    ],
                },
                totalRecords: 0,

            };

        },
        mounted() {
            this.loadItems();
            this.dashboardData();
            this.AirlinesDashboard();
            this.MonthlyAirlinesDashboard();
            this.formData();
            this.getBarAirLinesPerformanceIncomingandOutgoing();
            this.getBarAirlinesMonthlyPerformance();
            this.getBarAirlinesMonthlyCountrywiseVisit();
            this.getBarAirlinesYearlyCountrywiseVisit();
            this.getPieAirLinestMonthlyPerformance();
            this.getBranchEmployeeDetails();

        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            clearInput() {
                this.serverParams.fullName = null;
                this.serverParams.flightNumber = null;
                this.serverParams.destionation = null;
                /* this.loadItems();*/
            },
            async formData() {
                axios.get("Aviation/DDLAirDestinationByIDAsync")
                    .then(response => {
                        this.Destinations = response.data
                    }).catch(err => {
                        console.log(err)
                    });

                axios.get("Aviation/DDLAirDetailListAsync")
                    .then(response => {
                        this.airList = response.data
                    }).catch(err => {
                        console.log(err)
                    });
            },

            

            fullName(rowObj) {
                return rowObj.firstName + '   ' + rowObj.lastName
            },
            formResponse(responseObj) {
                this.snackbar.color = responseObj.status ? 'success' : 'red'
                this.snackbar.text = responseObj.text
                this.snackbar.snackbar = true
                this.loadItems();
            },
            dialogueClose() {
                this.customerData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
                this.loadItems()
            },

            async loadItems() {
                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    FullName: this.serverParams.fullName,
                    Groupby: this.serverParams.flightNumber,
                    Destination: this.serverParams.destionation



                };
                axios.post("Aviation/GetDailyPassengerInformationListAsync", param).then(response => {
                    this.rows = response.data.data;
                    console.log('this.rows', this.rows)
                    this.totalRecords = response.data.totalCount;
                })
                    .catch(err => {
                        console.log(err)
                    });

            },

            //async loadItems() {
            //    this.snackbar.isLoading = true;
            //    let param = {
            //        PageSize: this.serverParams.perPage,
            //        PageNo: this.serverParams.page,
            //        OrderType: this.serverParams.sort[0].type,
            //        OrderBy: this.serverParams.sort[0].field,
            //        Country: this.serverParams.columnFilters.country,
            //        FirstName: this.serverParams.columnFilters.firstName,
            //        CitizenShipNumber: this.serverParams.columnFilters.citizenShipNumber,
            //        PassportNumber: this.serverParams.columnFilters.passportNumber,
            //        LicenseNumber: this.serverParams.columnFilters.licenseNumber,
            //        FromDate: null,
            //        ToDate: null
            //    };
            //    axios.post('Aviation/GetDailyPassengerInformationListAsync', param)
            //        .then(response => {
            //            this.rows = response.data.data;
            //            this.totalRecords = response.data.totalCount;
            //            this.snackbar.loading = false;

            //        })
            //        .catch(err => {
            //            console.log(err)
            //        })
            //},

            async searchInput() {
                this.loadItems()
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.customerData.editItemIndex = item.personInformationID
            },
            deleteItemRoom(item) {
                console.log(item);
                this.snackbar.dialogDelete = true;
                this.customerData.deleteFormData.url = "Aviation/DeletePassengerInformationByIDAsync/" + item.personInformationID;
                this.loadItems();
            },
            onResponseDelete() {
                this.snackbar.dialogDelete = false;
                this.loadItems();
            },
            async dashboardData() {
                const dashboardData = await axios.get(
                    "Branch/GetBranchInformationByBranchID"
                );
                
                this.branchInformation = dashboardData.data;
                console.log("this.branchDetails", this.branchInformation);
            },
            async AirlinesDashboard() {
                const { data } = await publicAxios.post("Aviation/GetAirlinesDailyDetailsAsync")
                this.AviationDailyData = data
                console.log('this.AviationDailyData', this.AviationDailyData)
            },

            async MonthlyAirlinesDashboard() {
                const { data } = await publicAxios.post("Aviation/GetAirlinesMonthlyDetailsAsync")
                this.AviationMonthlyData = data
                console.log('this.GetAirlinesMonthlyDetailsAsync', this.AviationMonthlyData)
            },

            async getBarAirLinesPerformanceIncomingandOutgoing() {
                const { data } = await publicAxios.get('Aviation/GetAirlinePassengerDestinationWiseCountAsync')
                let formatedData = this.getChartData(data)
                this.chartData = formatedData
                console.log('this.getBarAirLinesPerformanceIncomingandOutgoing', this.chartData)
            },
            async getBarAirlinesMonthlyPerformance() {
                const { data } = await publicAxios.get('Aviation/GetAirlinePassengerMonthCountAsync')
                let formatedData = this.getChartData(data)
                this.monthlyData = formatedData
                console.log('this.getBarAirportMonthlyPerformance', this.monthlyData)
            },
            async getBarAirlinesMonthlyCountrywiseVisit() {
                const { data } = await publicAxios.get('Aviation/GetHAirCountryWiseTouristVisitDetails')
                let formatedData = this.getChartData(data)
                this.monthlyCustomerVisitCountrywiseData = formatedData
                console.log('this.getHAirCountryWiseTouristVisitDetails', this.monthlyCustomerVisitCountrywiseData)
            },
            async getBarAirlinesYearlyCountrywiseVisit() {
                const { data } = await publicAxios.get('Aviation/GetAirYearlyCountryWiseTouristVisitDetails')
                let formatedData = this.getChartData(data)
                this.yearlyCustomerVisitCountrywiseData = formatedData
                console.log('this.getAirYearlyCountryWiseTouristVisitDetails', this.yearlyCustomerVisitCountrywiseData)
            },
            async getPieAirLinestMonthlyPerformance() {
                const { data } = await publicAxios.post('Aviation/GetDailyAirlinesFlightsAsync')
                let formatedData = this.getChartData(data)
                this.data = formatedData
                console.log('this.getPieAirportMonthlyPerformance', this.data)
            },
            async getBranchEmployeeDetails() {
                const { data } = await publicAxios.post(
                    "Aviation/GetAirEmployeeDetails/0"
                );
                this.airEmployeeDetails = data;
                console.log("this.airEmployeeDetails", this.airEmployeeDetails);
            },

            getChartData(data) {
                console.log(data)
                let arr = []
                arr[0] = Object.keys(data[0])
                for (let i = 1; i < data.length + 1; i++) {
                    arr[i] = Object.values(data[i - 1])
                }
                return arr;
            }
        }
    };
</script>


<!--<script>
    /*import AviationDashboardMenu from "./AviationDashboardMenu";*/
    import axios from "axios";
    import {mapGetters} from "vuex";
    import DivisionDashboard from '../dashboard/DivisionDashboard'

    export default {
        name: "AviationSettings",
        computed: {
            ...mapGetters(['getSystemUserData']),
            isDivision() {


                return this.getSystemUserData.role === 'Division'
            }
        },
        data() {
            return {
                snackbar: {
                    formModal: false,
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    drawer: null,
                    drawerRight: null,
                    right: false,
                    dashboardItems: [
                        {
                            name: "Passengers",
                            link: "/AviationCustomerList"
                        },
                        {
                            name: "Generate Report",
                            link: "/report"
                        },
                        {
                            name: "Aviation Profile",
                            link: "/branch"
                        },
                        {
                            name: "Survey",
                            link: "/BranchSurveyProjects"
                        },
                    ],
                    breadcrumbsItems: [
                        {
                            text: "Dashboard",
                            disabled: true,
                            to: "/dashboard",
                            exact: true
                        }],
                },
                dynamicComponent: {
                    current: 'AirDestinationForm'
                },
                customerData: {
                    updateItem: {}
                },
                branchInformation: [],
            }
        },
        methods: {
            clickEvent(route) {
                this.$router.push({name: route})
            },
            addFormModal(route) {
                this.snackbar.formModal = true
                this.dynamicComponent.current = route
            },
            dialogueClose() {
                this.customerData.updateItem = {}
                this.snackbar.formModal = false
            },
            formResponse(responseObj) {
                this.snackbar.color = responseObj.status ? 'success' : 'red'
                this.snackbar.text = responseObj.text
                this.snackbar.snackbar = true
            },
            openDrawer() {
                this.snackbar.drawerRight = !this.snackbar.drawerRight
            },
            async dashboardData() {
                const dashboardData = await axios.get(
                    "Branch/GetBranchInformationByBranchID"
                );
                this.branchInformation = dashboardData.data;
            }
        },
        mounted() {
            this.dashboardData()
        },
        components: {
            DivisionDashboard
            /* 'menu-list': AviationDashboardMenu,*/
            /*// eslint-disable-next-line vue/no-unused-components
            'AirDestinationForm': () => import(/!* webpackChunkName: "AirDestinationForm" *!/ '../aviation/airDestination/AirDestinationForm'),
            // eslint-disable-next-line vue/no-unused-components
            'AirDetailForm': () => import(/!* webpackChunkName: "AirDetailForm" *!/ '../aviation/airDetail/AirDetailForm'),
            // eslint-disable-next-line vue/no-unused-components
            'AirServicesForm': () => import(/!* webpackChunkName: "AirServicesForm" *!/ '../aviation/airServices/AirServicesForm'),
            // eslint-disable-next-line vue/no-unused-components
            'CustomerForm': () => import(/!* webpackChunkName: "CustomerForm" *!/ '../aviation/customer/CustomerForm')*/
        }
    }
</script>-->

<style scoped lang="scss">
    .options {
        border: 2px solid white;
    }

    @media screen and (min-width:1903px) {
        #subboxtitle {
            margin-top: 20px;
            color: white;
        }

        #passengers {
            display: none;
        }
    }

    @media screen and (max-width:1903px) {
        #subboxtitle {
            margin-top: 20px;
            color: white;
        }
        #passengers {
            display: none;
        }
    }




    @media screen and (max-width:1667px) {
        .col-sm-3 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
        #passengers {
            display: none;
        }
    }

    @media screen and (max-width:1534px) {
        .col-sm-6 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        .col-sm-3 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        #passengers {
            float: right;
            margin-right: 10px;
            font-size: 26px;
            color: white;
            display:block;
        }
        #title{
            display:none;
        }

        
    }

    @media screen and (max-width:751px) {
        #subboxtitle {
            margin-top: 20px;
            font-size: 12px;
            color: white;
        }
        .col-3 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
</style>